import React from "react";

export default ({ color = "white" }) => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.16672 24.1637V29.3153C9.16672 30.6537 9.90005 31.9003 11.0734 32.542L20.2401 37.547C21.3401 38.152 22.6601 38.152 23.7601 37.547L32.9267 32.542C34.1001 31.9003 34.8334 30.6537 34.8334 29.3153V24.1637L23.7601 30.2137C22.6601 30.8187 21.3401 30.8187 20.2401 30.2137L9.16672 24.1637ZM20.2401 6.45367L4.78505 14.887C3.52005 15.5837 3.52005 17.417 4.78505 18.1137L20.2401 26.547C21.3401 27.152 22.6601 27.152 23.7601 26.547L38.5 18.4987V29.3337C38.5 30.342 39.3251 31.167 40.3334 31.167C41.3417 31.167 42.1667 30.342 42.1667 29.3337V17.582C42.1667 16.9037 41.8 16.2987 41.2134 15.9687L23.7601 6.45367C22.6601 5.86701 21.3401 5.86701 20.2401 6.45367Z" fill="#333333" />
    <path d="M38.7948 7.54584C37.9133 6.94492 36.7115 7.17239 36.1106 8.05391C35.5097 8.93544 35.7372 10.1372 36.6187 10.7381C37.5002 11.339 38.702 11.1116 39.3029 10.23C39.9038 9.34851 39.6763 8.14676 38.7948 7.54584Z" fill="#333333" />
    <path d="M37.0649 7.41783C36.3436 6.92617 35.3604 7.11228 34.8687 7.83353C34.377 8.55478 34.5632 9.53803 35.2844 10.0297C36.0056 10.5214 36.9889 10.3352 37.4806 9.61399C37.9722 8.89275 37.7861 7.90949 37.0649 7.41783Z" fill="#73A77B" />
    <path d="M37.1079 35.8587C36.7161 34.8534 35.5836 34.3561 34.5783 34.7478C33.5731 35.1396 33.0757 36.2722 33.4675 37.2774C33.8593 38.2827 34.9918 38.7801 35.9971 38.3883C37.0024 37.9965 37.4997 36.864 37.1079 35.8587Z" fill="#333333" />
    <path d="M35.7382 34.762C35.4177 33.9395 34.491 33.5326 33.6685 33.8532C32.846 34.1737 32.4391 35.1003 32.7597 35.9228C33.0802 36.7453 34.0069 37.1523 34.8294 36.8317C35.6519 36.5112 36.0588 35.5845 35.7382 34.762Z" fill="#FFB000" />
    <path d="M8.91407 7.50057C9.70742 8.16864 10.8921 8.06707 11.5602 7.27372C12.2282 6.48037 12.1267 5.29567 11.3333 4.6276C10.54 3.95954 9.35528 4.06111 8.68722 4.85445C8.01915 5.6478 8.12072 6.83251 8.91407 7.50057Z" fill="#333333" />
    <path d="M10.5746 7.79344C11.2237 8.34003 12.193 8.25694 12.7396 7.60783C13.2862 6.95873 13.2031 5.98942 12.554 5.44283C11.9049 4.89623 10.9356 4.97933 10.389 5.62843C9.84236 6.27753 9.92546 7.24684 10.5746 7.79344Z" fill="#F76E9C" />
  </svg>



);

